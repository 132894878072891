import { takeUntil } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PwaService implements OnDestroy {

  private deferredPrompt = new BehaviorSubject<any>(null);
  deferredPrompt$ = this.deferredPrompt.asObservable();

  constructor(private readonly swUpdate: SwUpdate) {
    this.swUpdate.available
      .pipe(takeUntil(this.deferredPrompt$))
      .subscribe(() => this.confirmAppUpdate());
  }

  private confirmAppUpdate(): void {
    if (confirm('New ATOL version is available! Would you like to update it?')) window.location.reload();
  }

  setDeferredPrompt(event: any): void {
    this.deferredPrompt.next(event);
  }

  ngOnDestroy(): void {
    this.deferredPrompt.complete();
  }
}
