import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'scanner', loadChildren: () => import('./scanner/scanner.module').then((m) => m.ScannerModule) },
  { path: 'form', loadChildren: () => import('./form/form.module').then((m) => m.FormModule) },
  { path: '**', redirectTo: 'form', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
