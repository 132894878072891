import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take, takeUntil } from 'rxjs/operators';
import { PwaService } from './core/services/pwa.service';
import { SnackBarService } from './core/services/snack-bar.service';
import { DestroyService } from './core/services/destroy.service';

const { version } = require('../../package.json');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService]
})
export class AppComponent implements OnInit {

  deferredPrompt$ = this.pwaService.deferredPrompt$;
  appVersion!: string;

  @HostListener('window:beforeinstallprompt', ['$event'])
  onBeforeinstallprompt(event: any): void {
    event?.preventDefault();
    this.pwaService.setDeferredPrompt(event);
  }

  @HostListener('window:appinstalled')
  onAppinstalled(): void {
    this.pwaService.setDeferredPrompt(null);
  }

  constructor(
    private pwaService: PwaService,
    private snackBar: MatSnackBar,
    private snackBarService: SnackBarService,
    private readonly destroy$: DestroyService
  ) {}

  ngOnInit(): void {
    this.appVersion = `ATOL ${version}`;

    this.snackBarService.activeSnack$
      .pipe(takeUntil(this.destroy$))
      .subscribe(snack => this.snackBar.open(snack.message, snack.action, snack.config));
  }

  saveToHomeScreen(): void {
    this.deferredPrompt$
      .pipe(take(1))
      .subscribe(deferredPrompt => deferredPrompt?.prompt());
  }
}
