import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { SnackBar } from '../models/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService implements OnDestroy {
  private activeSnack = new Subject<SnackBar>();
  activeSnack$ = this.activeSnack.asObservable();

  setPositiveSnack(message: string, action = 'Close', duration = 5000): void {
    this.activeSnack.next({ message, action, config: { duration, panelClass: 'positive' }});
  }

  setNegativeSnack(message: string, action = 'Close', duration = 5000): void {
    this.activeSnack.next({ message, action, config: { duration, panelClass: 'negative' }});
  }

  ngOnDestroy(): void {
    this.activeSnack.complete();
  }
}
