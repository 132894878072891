<app-header>
    <a matRipple routerLink="/">
        <span class="icon"></span>
    </a>
    <button mat-button *ngIf="deferredPrompt$ | async" class="hero secondary inverted" (click)="saveToHomeScreen()">Save to homescreen</button>
</app-header>
<app-progress-bar></app-progress-bar>
<main>
    <router-outlet></router-outlet>
</main>
<mat-divider></mat-divider>
<footer>
    <i class="version">{{ appVersion }}</i>
</footer>
