import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProgressBarService } from '../../services/progress-bar.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent {
  
  isLoading$ = this.progressBarService.isLoading$;

  constructor(private progressBarService: ProgressBarService) {}
  
}
