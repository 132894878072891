import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService implements OnDestroy {
  private isLoading = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoading.asObservable();

  setLoading(isLoading: boolean): void {
    this.isLoading.next(isLoading);
  }

  ngOnDestroy(): void {
    this.isLoading.complete();
  }
}
